<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-text-field
            class="mt-4"
            v-model="order.numero_orden"
            label="Número de orden:"
            :rules="[]"
            disabled
          />
          <kn-select
            label="Estatus:"
            v-model="order.id_estatus_orden"
            :rules="[]"
            :items="orderStatus"
            item-value="id"
            item-text="dato"
          />
          <v-row>
            <v-col class="py-0" cols="4">
              <v-subheader>Fecha elaboración:</v-subheader>
            </v-col>
            <v-col class="py-0" cols="4">
              <v-text-field
                v-model="order.fecha"
                dense
                outlined
                type="date"
                hint="DD / MM / YYYY"
                persistent-hint
                disabled
              >
                <template v-slot:append-outer>
                  <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <kn-form-subtitle
            title="Información del cliente"
          />
          <kn-select
            label="Alumno:"
            v-model="order.id_alumno"
            :rules="[]"
            :items="students"
            item-value="id"
            item-text="datos_personales.primer_nombre"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="RFC:"
            v-model.number="order.rfc"
            :rules="[]"
            disabled
          />
          <kn-text-area
            class="mt-4"
            label="Dirección:"
            v-model.number="order.fullAddress"
            :rules="[]"
            disabled
          />

          <kn-form-subtitle
            title="Resumen de la orden"
          />
          <kn-text-field
            class="mt-4"
            label="Total de la orden:"
            v-model.number="order.total_orden"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="Sub total de la orden:"
            v-model.number="order.sub_total"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="Impuestos:"
            v-model.number="order.total_impuestos"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="Descuento:"
            v-model.number="order.total_descuento"
            :rules="[]"
            disabled
          />
          <kn-select
            label="Tipo de envío:"
            v-model="order.id_tipo_envio"
            :rules="[]"
            :items="shippingTypes"
            item-value="id"
            item-text="dato"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="Costo de envío:"
            v-model.number="order.costo_envio"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            label="Peso volumétrico:"
            v-model.number="order.peso_volumetrico"
            :rules="[]"
            disabled
          />
          <kn-form-subtitle
            title="Productos"
          />
          <v-data-table
            class="mt-4"
            :headers="headers"
            :items="items"
            loading-text="Cargando... Espera por favor"
            no-data-text="Aún no hay registros"
            no-results-text="No se encontraron coincidencias"
            :footer-props="{
              itemsPerPageText: 'Elementos por página'
            }"
          >
            <template v-slot:[`item.total_impuesto`]="{ item }">
              {{ item.total_impuesto.toFixed(2) }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total.toFixed(2) }}
            </template>
          </v-data-table>
          <kn-form-action-buttons
            acceptBtnLabel="Actualizar estatus"
            :disableAccept="!enableBtnAccept"
            @accept="save"
            @cancel="cancel"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
// import KnTabs from '@/components/KnTabs.vue'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnTextArea from '../../../components/inputs/KnTextArea.vue'
import KnFormTitle from '../../../components/KnFormTitle.vue'
import KnBackToTopButton from '../../../components/forms/KnBackToTopButton.vue'
export default {
  components: {
    KnFormSubtitle,
    KnTextField,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnTextArea,
    KnFormTitle,
    KnBackToTopButton
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin,
    addressUtilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Detalle de la orden', value: 100 },       
      ],
      valueDeterminate: 100,
      routerName: 'Ordenes',
      orderId: null,
      order: {
        numero_orden: null,
        id_estatus_orden: null,
        id_alumno: null,
        id_datos_facturacion: null,
        id_direccion: null,
        peso_volumetrico: null,
        sub_total: null,
        total_impuestos: null,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_orden: null,
        id_moneda: null,
        descuento_aplicado: false,
        fecha_elaboracion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      products: [],
      orderStatus: [],
      students: [],
      shippingTypes: [],
      measurementUnits: [],
      coins: [],
      items: [],
      headers: [
        { text: 'Producto', value: 'producto.nombre_producto', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'producto.peso_volumetrico', class: 'purple--text' },
        { text: 'Cantidad', value: 'unidades', class: 'purple--text'},
        { text: 'Unidad de medida', value: 'unidad_medida.dato', class: 'purple--text'},
        { text: 'Precio unitario', value: 'precio_unitario', class: 'purple--text'},
        { text: 'Sub total', value: 'sub_total', class: 'purple--text'},
        { text: 'Impuestos', value: 'total_impuesto', class: 'purple--text'},
        { text: 'Total', value: 'total', class: 'purple--text'},
      ],

       /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar orden' : 'Detalle de la orden'
    },
    isEditMode() {
      return this.entity !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Estatus actualizado con éxito!' : 'Orden registrada con éxito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    enableBtnAccept() {
      return this.order.id_estatus_orden !== this.entity.estatus_orden.id
    },
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'

    this.orderStatus = await this.fetchResultsAll('ordenes', 'mv-estatus-orden-all')
    this.students = await this.fetchResultsByEI('personas', 'alumno', this.institutionId)
    this.shippingTypes = await this.fetchResultsAll('ordenes', 'tipo-envio-all')
    this.products = await this.fetchResultsByEI('productos', 'producto', this.institutionId)
    this.measurementUnits = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    
    if (this.isEditMode) {
      // console.log('Se deben llenar los campos');
      this.fillData()
    } else {
      this.order.id_institucion_educativa = this.institutionId
      this.order.id_autor = this.userData.id
    }
    this.showAlert = false
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async updateOrderStatus() {
      try {
        this.alertText = 'Actualizando estatus de orden'
        const statusObj = {
          id_orden: this.orderId,
          id_estatus_orden: this.order.id_estatus_orden
        }
        const response = await this.postObj('/ordenes/update-estatus-orden', statusObj, 'Actualizar')
        if (response.error) {
          this.errors.push(response.error)
        }
      } catch (error) {
        console.error('Error al intentar actualizar el estatus de la orden', error);
        this.errors.push('No se pudo actualizar el estatus de la orden')
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        await this.updateOrderStatus()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario o crear movimiento inventario')
      }
    },
    async save() {
      if (this.isEditMode) {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.id_moneda = productPrice.moneda
    },
    async fillData() {
      this.orderId = this.entity.id
      this.order = {
        id: this.orderId,
        numero_orden: this.entity.numero_orden,
        id_estatus_orden: this.entity.estatus_orden.id,
        id_alumno: this.entity.alumno.id,
        id_datos_facturacion: this.entity.datos_facturacion,
        rfc: this.entity.datos_facturacion.tax_id,
        id_direccion: this.entity.direccion_envio.id,
        fullAddress: null,
        peso_volumetrico: this.entity.peso_volumetrico,
        sub_total: this.entity.sub_total,
        total_impuestos: this.entity.total_impuestos,
        id_tipo_envio: this.entity.tipo_envio.id,
        costo_envio: this.entity.costo_envio,
        total_descuento: this.entity.total_descuento,
        total_orden: this.entity.total_orden,
        id_moneda: this.entity.moneda.id,
        descuento_aplicado: false,
        fecha_elaboracion: this.entity.fecha_elaboracion,
        fecha: this.entity.fecha,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId
      }

      const address = await this.fetchAddresById(this.entity.direccion_envio.id, this.institutionId)
      const fullAddress = this.fullAddressWithZipObj(address)
      this.order.fullAddress = fullAddress

      const details = await this.fetchResultsWithParams('ordenes', 'detalle-orden-by-orden', {id_orden: this.orderId})
      for (const detail of details) {
        const subtotal = parseFloat(detail.sub_total)
        const taxPercent = parseFloat(detail.impuesto)
        const taxValue = subtotal * taxPercent
        const total = subtotal + taxValue

        const item = {
          ...detail,
          total_impuesto: taxValue,
          total: total
        }
        this.items.push(item)
      }

    }
  }
}
</script>

<style>

</style>